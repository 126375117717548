<script setup lang="ts">
const analyticsStore = useAnalyticsStore();
</script>

<template>
  <a
    href="https://www.tbank.ru/reviews/company/begemot/154425/"
    target="_blank"
    class="tinkoff_badge"
    @click="analyticsStore.clickTinkoffTrustBadge()"
  >
    <span class="tinkoff_badge__badge">4.4</span>
    <div class="tinkoff_badge__content">
      <span>рейтинг</span>
      <NuxtImg
        src="/images/tbank.svg"
        alt="T-Bank"
        height="14"
        width="39"
      />
    </div>
  </a>
</template>

<style scoped lang="scss">
.tinkoff_badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 14px;
  background: $line-stroke-gray;
  border-radius: 16px;
  opacity: 0.4;
  height: 54px;
  user-select: none;
  color: $foreground-contrast;

  transition: opacity 150ms ease-in-out;
  &:hover {
    opacity: 0.55;
  }

  span {
    font-size: 14px;
    font-weight: 500;
  }

  .tinkoff_badge__badge {
    font-size: 28px;
    font-weight: 600;
  }

  .tinkoff_badge__content {
    display: flex;
    flex-direction: column;
    gap: 2px;

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
    }
  }
}
</style>
