<script setup lang="ts">
import VAccordion from '~/components/VAccordion.vue';

const faq = ref([
  {
    title: 'Что я получу в платной версии проекта?',
    content:
      'Платная версия включает в себя полную версию проекта, в которую входит структурированный документ в формате MS Word (около 15 страниц), доступный к скачиванию. В документе содержатся все важные разделы, включая список источников и полезные ссылки. Доступ к этим материалам защищен и доступен только Вам.',
  },
  {
    title: 'Попадет ли мой проект в открытый доступ?',
    content:
      'Нет, содержание проекта доступно только Вам. В открытом доступе будет размещено только краткое описание (название, цель и задачи и т.д.), однако при покупке опции "Скрыть проект" Вы можете скрыть свой проект полностью.',
  },
  {
    title: 'Проект будет уникальным?',
    content: 'Да, каждый проект будет уникальным, даже если тема совпадает с уже существующей на сайте.',
  },
  {
    title: 'Можно ли купить готовый проект?',
    content: 'Да, можно приобрести проект по уже существующей теме, сгенерировав свой собственный на основе выбранной темы.',
  },
  {
    title: 'Как получить оплаченный проект?',
    content: 'Все оплаченные проекты сохраняются на странице <a href="/profile">Мои заказы</a> и остаются доступными бессрочно.',
  },
  {
    title: 'Кому принадлежат права на проект?',
    content:
      'Права на проект принадлежат пользователю, оплатившему его. Исключительное право распространяется на закрытую часть проекта, а неисключительное — на его публичное описание. Администратор сайта может размещать публичную часть проекта в открытом доступе.',
  },
]);
</script>

<template>
  <div class="new-faq">
    <div class="container">
      <h2>Часто задаваемые вопросы</h2>
      <div class="new-faq__group">
        <VAccordion
          v-for="(item, idx) in faq"
          :key="idx"
          :title="item.title"
          :content="item.content"
          class="w-100"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.new-faq {
  padding: 80px 0;
  @include media-breakpoint-down(sm) {
    padding: 64px 0;
  }

  h2 {
    font-size: 36px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 48px;
    color: $foreground-contrast;
    text-align: center;

    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
  }

  .new-faq__group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
  }
}
</style>
