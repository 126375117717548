<script setup lang="ts">
import { X } from 'lucide-vue-next';
import { isBlackFridayNow } from '~/constants/black-friday';

const isClosed = ref(false);
const timeWillBeClosed = 60 * 60; // 1 hour
const blackFridayBannerShownDate = useCookie<string | null>('blackFridayBannerShownDate', {
  maxAge: timeWillBeClosed,
});

// Check if the banner should be shown
const isTimePassed = computed(() => !blackFridayBannerShownDate.value);
const isOpen = computed(() => !isClosed.value && isTimePassed.value && isBlackFridayNow.value);

// Close the banner and set the cookie
const onClose = () => {
  isClosed.value = true;
  blackFridayBannerShownDate.value = new Date().toISOString();
};

// Watch for `isOpen` changes to manage Jivo layout offsets
const jivoStore = useJivoStore();

watchEffect(() => {
  if (isOpen.value) {
    jivoStore.bottomOffset = 118;
    jivoStore.rightOffset = 20;
  } else {
    jivoStore.resetLayout();
  }
});

onMounted(() => {
  if (isOpen.value) {
    useJivoStore().bottomOffset = 118;
    useJivoStore().rightOffset = 20;
  }
});

onBeforeUnmount(() => {
  jivoStore.resetLayout();
});
</script>

<template>
  <div
    v-if="isOpen"
    class="black-friday-banner"
  >
    <span class="percent">-11%</span>
    <div class="text">
      <span>
        на все типы <br />
        работ 11.11 - 15.11
      </span>
    </div>
    <v-button
      schema="icon"
      style="color: white; padding: 10px 0"
      @click="onClose"
    >
      <X
        width="24"
        height="24"
      />
    </v-button>
  </div>
</template>

<style scoped lang="scss">
.black-friday-banner {
  user-select: none;
  position: fixed;
  left: calc((100vw - 100%) / 2 + 50%);
  bottom: 20px;
  translate: -50%;
  display: flex;
  width: min(calc(100vw - 40px), 721px);
  padding: 20px;
  gap: 24px;
  flex-direction: row;
  background: #2b2433;
  border-radius: 20px;
  color: white;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(lg) {
    width: 600px;
    bottom: 28px;
  }

  .hidden-lg {
    display: inline;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .shown-lg {
    display: none;
    @include media-breakpoint-up(lg) {
      display: inline;
    }
  }

  .percent {
    font-size: 48px;
    font-weight: 400;
    line-height: 100%;

    @include media-breakpoint-up(lg) {
      font-size: 56px;
    }
  }

  .text {
    font-size: 16px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }
  }
}
</style>
