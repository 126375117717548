<script setup lang="ts">
import type { EntityType } from '~/api/types';
import ProjectCard from '~/components/cards/ProjectCard.vue';
import { entities } from '~/enitityHelper';
import VButton from '~/components/VButton.vue';
import { getApiEndpoint } from '~/api/auth';

const props = withDefaults(defineProps<{ entity: EntityType }>(), { entity: 'project' });

const projectsEndpoint = getApiEndpoint()+'projects';
const { data: projects } = await useCachedAsyncData('lastProjects' + props.entity, () => $fetch(projectsEndpoint, {query: {entity: props.entity}}), {
  serverMaxAge: 60,
  clientMaxAge: 60,
});
const projectsPerPage = 8;
const page = ref(1);
const shownProjects = computed(() => projects.value?.slice(0, projectsPerPage * page.value));
const entityMeta = entities[props.entity];
</script>

<template>
  <div
    v-if="projects && shownProjects"
    class="last-projects"
  >
    <div class="container">
      <h2>{{ entityMeta.lastProjectsBlock.title }}</h2>
      <div class="last-projects__projects">
        <ProjectCard
          v-for="(item, idx) in shownProjects"
          :key="idx"
          :schema="item.payed_status ? 'pro-white' : 'default'"
          :project-card="item"
          :show-header="false"
        />
      </div>
      <v-button
        schema="outline"
        class="more-button"
        :class="{ hidden: shownProjects.length >= projects.length }"
        @click="page++"
      >
        Показать еще
      </v-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.last-projects {
  padding-top: 48px;
  padding-bottom: 80px;
  @include media-breakpoint-down(sm) {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  @include media-breakpoint-down(md) {
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    margin-bottom: 48px;
    color: $foreground-contrast;
    text-align: start;
    font-size: 36px;
    font-weight: 600;
    line-height: 32px;

    @include media-breakpoint-down(md) {
      text-align: center;
      font-size: 28px;
    }
  }

  .last-projects__projects {
    display: grid;
    gap: 16px;
    margin-bottom: 16px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    @include media-breakpoint-down(lg) {
      margin-bottom: 12px;
      gap: 12px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .more-button {
    width: 100%;

    &:not(:hover) {
      background: white;
    }

    &.hidden {
      display: none;
    }
  }
}
</style>
