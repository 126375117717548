<script setup lang="ts">
import { useElementVisibility } from '@vueuse/core';
import { getReviews } from '~/api/reviews';
import type { Review } from '~/api/types.ts';
import ReviewCard from '~/components/cards/ReviewCard.vue';
import { useAnalyticsStore } from '~/stores/AnalyticsStore';
import { getApiEndpoint } from '~/api/auth';

const blockRef = ref<HTMLDivElement>();
const isInViewport = useElementVisibility(blockRef);
const isSeen = ref(false);
const analyticsStore = useAnalyticsStore();
watch(isInViewport, () => {
  if (isInViewport && !isSeen.value) {
    analyticsStore.hasSeenReviews();
    isSeen.value = true;
  }
});
const reviewsEndpoint = getApiEndpoint()+'reviews';
const { data: reviews } = await useCachedAsyncData('reviews', () => $fetch(reviewsEndpoint), {
  serverMaxAge: 60*60,
  clientMaxAge: 60*30,
});
</script>

<template>
  <div
    v-if="reviews?.length"
    ref="blockRef"
    class="feedback"
  >
    <div class="container feedback-container">
      <h2>Последние отзывы</h2>
      <div class="feedback__group">
        <review-card
          v-for="review in reviews"
          :key="review.id"
          :review-card="review"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.feedback {
  background: $background-theme-fade;
  padding: 128px 0;
  @include media-breakpoint-down(sm) {
    padding: 64px 0;
  }

  .feedback-container {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  h2 {
    margin-bottom: 48px;
    color: $foreground-contrast;
    text-align: start;
    font-size: 36px;
    font-weight: 600;
    line-height: 32px;

    @include media-breakpoint-down(md) {
      font-size: 28px;
      text-align: center;
    }
  }

  .feedback__group {
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
